import { Box, Button, Grid, Typography, Stack } from '@arquivei/atenas';

const ExploreApiSection = () => {
  return (
    <Box component="section" data-testid="explore-api-section">
      <Grid container sx={{ py: { xs: 6, md: 12 } }}>
        <Stack direction="column" alignItems="center" spacing={4}>
          <Grid xs={12} md={12}>
            <Typography level="display1" color="primary" gutterBottom>
              Explore nossa API e tire o melhor de suas notas
            </Typography>
          </Grid>
          <Grid xs={12} md={10}>
            <Typography level="h2" textColor="text.secondary" gutterBottom>
              Comece agora a testar nossas APIs e integrações de forma rápida e
              segura via Sandbox e deixe de lado toda burocracia.
            </Typography>
          </Grid>
          <Grid xs={12} md={2}>
            <Button
              component="a"
              href="/sandbox"
              color="success"
              variant="solid"
              size="lg"
              sx={{ mt: 4 }}
            >
              Testar API agora
            </Button>
          </Grid>
        </Stack>
      </Grid>
    </Box>
  );
};

export default ExploreApiSection;
