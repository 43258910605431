import { Box, Grid, Typography, Stack } from '@arquivei/atenas';
import { Card, CardContent, CardMedia } from '@mui/material';
import Image from 'next/image';

import boticarioLogo from '../../assets/boticario-logo.svg';
import ceaLogo from '../../assets/cea-logo.svg';
import filesTime from '../../assets/files-time.svg';
import ifoodLogo from '../../assets/ifood-logo.svg';
import longpingLogo from '../../assets/longping-logo.svg';
import mondelezLogo from '../../assets/mondelez-logo.svg';
import notebookSync from '../../assets/notebook-sync-cloud.svg';
import nubankLogo from '../../assets/nubank-logo.svg';
import viaLogo from '../../assets/via-logo.svg';
import xmlFiles from '../../assets/xml-files.svg';

const companiesUsingOurApis = [
  {
    img: ceaLogo,
    name: 'C&A',
    width: 62,
    height: 47,
  },
  {
    img: boticarioLogo,
    name: 'Boticário',
    width: 179,
    height: 33,
  },

  {
    img: ifoodLogo,
    name: 'iFood',
    width: 87,
    height: 45,
  },
  {
    img: viaLogo,
    name: 'Via',
    width: 74,
    height: 54,
  },
  {
    img: mondelezLogo,
    name: 'Mondelez',
    width: 137,
    height: 33,
  },
  {
    img: nubankLogo,
    name: 'Nubank',
    width: 61,
    height: 33,
  },
  {
    img: longpingLogo,
    name: 'LongPing',
    width: 129,
    height: 33,
  },
];

const ourApiUseData = [
  {
    name: 'xmlDownload',
    title: '+2 Milhões',
    img: filesTime,
    description: 'De consultas são feitas diariamente via API Arquivei.',
  },
  {
    name: 'pdfs',
    title: '+560 Mil ',
    img: notebookSync,
    description:
      'Downloads de NFe, CTe e NFSe são realizados todos os dias via API integradas.',
  },
  {
    name: 'api',
    title: '+80% ',
    img: xmlFiles,
    description:
      'De produtividade nas automatizações que usam as integrações Arquivei. ',
  },
];

const OurApiUse = () => {
  return (
    <Box component="section" data-testid="resources-section">
      <Grid container>
        <Grid md={5} xs={12} sx={{ pt: { xs: 3, md: 9 } }}>
          <Typography level="display1" color="primary" gutterBottom>
            O que o uso de nossas APIs representa
          </Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent="space-between">
        {ourApiUseData.map((item) => {
          return (
            <Grid key={item.name} mt={8}>
              <Card
                data-testid="resources-card"
                sx={{
                  maxWidth: { xs: '100%', sm: '348px' },
                  height: '90%',
                  p: 4,
                }}
              >
                <CardMedia
                  component="img"
                  image={item.img.src}
                  width={item.img.width}
                  height={item.img.height}
                  sx={{
                    objectFit: 'none',
                    minHeight: '140px',
                  }}
                />

                <CardContent sx={{ minHeight: '100px', textAlign: 'center' }}>
                  <Typography level="h2">{item.title}</Typography>
                  <Typography level="body2" mt={2}>
                    {item.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Stack direction="row" justifyContent="center" mt={12}>
        <Typography level="display1" color="primary" gutterBottom>
          Mais de 10.000 empresas já utilizam nossa API
        </Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-around"
        mt={8}
        alignItems="center"
      >
        {companiesUsingOurApis.map((company) => {
          return (
            <Box key={company.name}>
              <Image
                src={company.img}
                alt={`logo da empresa ${company.name}`}
                width={company.width}
                height={company.height}
              />
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

export default OurApiUse;
