import { Box, Grid, Typography } from '@arquivei/atenas';
import { Card, CardContent, CardMedia } from '@mui/material';

import type { APIResource } from '../../types/resourceData';

import { resourcesData } from './mainResourcesData';

const MainResources = () => {
  return (
    <Box component="section" data-testid="resources-section">
      <Grid container>
        <Grid md={5} xs={12} sx={{ pt: { xs: 3, md: 9 } }}>
          <Typography level="display1" gutterBottom color="primary">
            Nossos principais recursos
          </Typography>
          <Typography>
            Desde download e envio de documentos fiscais até integração com ERPs
            via API, conte com nossos recursos para resolver problemas do seu
            negócio.
          </Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent="center">
        {resourcesData.map((item: APIResource) => {
          return (
            <Grid key={item.name} mt={4} mr={4}>
              <Card
                data-testid="resources-card"
                sx={{
                  maxWidth: { xs: '100%', sm: '375px' },
                  height: '100%',
                }}
              >
                <CardMedia
                  component="img"
                  image={item.img.src}
                  width={item.img.width}
                  height={item.img.height}
                  sx={{
                    objectFit: 'none',
                    backgroundColor: 'primary.solidBg',
                    minHeight: '140px',
                  }}
                />
                <CardContent sx={{ minHeight: '100px' }}>
                  <Typography component="p" level="h4">
                    {item.title}
                  </Typography>
                  <Typography level="body2">{item.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default MainResources;
