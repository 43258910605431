import { CssVarsProvider, defaultTheme, CssBaseline } from '@arquivei/atenas';
import React from 'react';

import Footer from './Footer/Footer';

interface Props {
  children: React.ReactNode;
}

export default function Layout({ children }: Props): React.ReactElement {
  return (
    <CssVarsProvider theme={defaultTheme}>
      <CssBaseline />
      {children}
      <Footer />
    </CssVarsProvider>
  );
}
