import { Box, IconButton } from '@arquivei/atenas';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';

export default function ScrollToIcon(): React.ReactElement {
  function handleClick() {
    window.scrollBy({ top: window.innerHeight, behavior: 'smooth' });
  }

  return (
    <Box display="flex" justifyContent="center">
      <IconButton
        data-testid="chevron-down"
        onClick={handleClick}
        sx={{
          bgcolor: 'transparent',
          position: 'absolute',
          bottom: 0,
          color: 'primary.contrastText',
          fontSize: '56px',
        }}
      >
        <ExpandMoreIcon fontSize="inherit" />
      </IconButton>
    </Box>
  );
}
