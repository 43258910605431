import { Box, Container } from '@arquivei/atenas';
import { CssBaseline } from '@mui/material';
import Head from 'next/head';

import communityBg from '../assets/community-cover.png';
import needhelpBg from '../assets/need-help.png';
import Header from '../components/Header/Header';
import ApiDocumentationSection from '../components/Home/ApiDocumentationSection';
import Community from '../components/Home/Community';
import ExploreApiSection from '../components/Home/ExploreApiSection';
import MainResources from '../components/Home/MainResources';
import NeedHelp from '../components/Home/NeedHelp';
import OurApiUse from '../components/Home/OurApiUse';
import Layout from '../components/Layout';
import ScrollToIcon from '../components/ScrollToIcon/ScrollToIcon';

const IndexPage = () => {
  return (
    <Layout>
      <Head>
        <title>Home</title>
      </Head>
      <CssBaseline />

      <Box minHeight="100vh" bgcolor="primary.solidBg">
        <Header />
        <Container maxWidth="xl">
          <ApiDocumentationSection />
        </Container>
        <ScrollToIcon />
      </Box>

      <Box sx={{ mb: 8 }}>
        <Container maxWidth="xl">
          <MainResources />
        </Container>
      </Box>

      <Box
        sx={{
          backgroundImage: `url(${communityBg.src})`,
          minHeight: '70vh',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Container maxWidth="xl">
          <Community />
        </Container>
      </Box>

      <Box sx={{ mb: 8 }}>
        <Container maxWidth="xl">
          <OurApiUse />
        </Container>
      </Box>

      <Box sx={{ backgroundColor: 'primary.50' }}>
        <Container>
          <ExploreApiSection />
        </Container>
      </Box>

      <Box
        sx={{
          backgroundImage: `url(${needhelpBg.src})`,
          minHeight: '70vh',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Container maxWidth="xl">
          <NeedHelp />
        </Container>
      </Box>
    </Layout>
  );
};

export default IndexPage;
