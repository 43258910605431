import { Box, Button, Grid, Typography, Stack } from '@arquivei/atenas';

import { COMMUNITY_URL } from '../../constants';

const Community = () => {
  return (
    <Stack direction="row" width="100%" minHeight="70vh">
      <Stack justifyContent="center">
        <Grid container>
          <Grid md={6} xs={12}>
            <Typography level="display1" textColor="common.white" gutterBottom>
              Comunidade Developers Arquivei
            </Typography>
            <Typography textColor="common.white" mt={4}>
              Tire dúvidas, encontre novas ideias, fale com outros
              desenvolvedores e descubra tudo que está acontecendo nas
              comunidades de desenvolvimento, acesse nossa página e aproveite
              tudo.
            </Typography>
          </Grid>
        </Grid>
        <Box>
          <Button
            component="a"
            href={COMMUNITY_URL}
            color="success"
            variant="solid"
            size="lg"
            sx={{ mt: 8 }}
          >
            Ir para comunidade
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

export default Community;
