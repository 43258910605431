import { Box, Button, Sheet, Link as DSLink, Stack } from '@arquivei/atenas';
import { AppBar, Toolbar, Container, Hidden } from '@mui/material';
import Image from 'next/image';
import React from 'react';

import { GAEvent } from '../../../lib/gtag';
import logo from '../../assets/logo-arquivei-contrast.svg';
import {
  INDEX_URL,
  DOCS_URL,
  ARQUIVEI_JOBS_URL,
  HELP_URL,
  COMMUNITY_URL,
} from '../../constants';

const handleClick = () => {
  GAEvent('Go to account creation', {
    category: 'engagement',
    label: 'button "Teste nossa API"',
    value: 1,
  });
};

export default function Header(): React.ReactElement {
  return (
    <AppBar
      position="sticky"
      data-testid="header"
      sx={{ bgcolor: 'primary.solidBg' }}
    >
      <Toolbar>
        <Container maxWidth="xl" sx={{ px: 0 }}>
          <Stack spacing={2} direction="row" alignItems="center">
            <Box sx={{ flexGrow: 1 }}>
              <DSLink href={INDEX_URL}>
                <Image width="170" height="48" alt="Arquivei logo" src={logo} />
              </DSLink>
            </Box>
            <Stack direction="row" spacing={1}>
              <Hidden smDown>
                <Sheet
                  invertedColors
                  variant="solid"
                  sx={{ bgcolor: 'transparent', border: 'none' }}
                >
                  <Button component="a" href={DOCS_URL} variant="plain">
                    DOCUMENTAÇÃO
                  </Button>
                  <Button component="a" href={COMMUNITY_URL} variant="plain">
                    COMUNIDADE
                  </Button>
                  <Button component="a" href={HELP_URL} variant="plain">
                    SUPORTE
                  </Button>
                  <Button
                    component="a"
                    href={ARQUIVEI_JOBS_URL}
                    variant="plain"
                  >
                    FAÇA PARTE
                  </Button>
                </Sheet>
              </Hidden>

              <Button
                component="a"
                variant="solid"
                href="/sandbox"
                color="success"
                onClick={handleClick}
              >
                TESTE NOSSA API
              </Button>
            </Stack>
          </Stack>
        </Container>
      </Toolbar>
    </AppBar>
  );
}
