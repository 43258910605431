import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  Stack,
} from '@arquivei/atenas';
import Image from 'next/image';
import type { StaticImageData } from 'next/image';
import React from 'react';

import certisignLogo from '../../assets/certisign-logo-53-26.svg';
import endeavorLogo from '../../assets/endeavor-promessas-logo-87-18.svg';
import fbLogo from '../../assets/facebook-icon-27-27.svg';
import googleCloudLogo from '../../assets/google-cloud-logo-122-20.svg';
import instagramLogo from '../../assets/instagram-icon-27-27.svg';
import linkedinLogo from '../../assets/linkedin-icon-24-24.svg';
import nortonLogo from '../../assets/norton-logo-67-26.svg';
import twitterLogo from '../../assets/twitter-icon-27-24.svg';
import youtubeLogo from '../../assets/youtube-icon-30-20.svg';

export type DataLinks = {
  section: string;
  contents: ContentsEntity[];
};
export type ContentsEntity = {
  label: string;
  url?: string;
  image?: StaticImageData;
};

export type Certificates = {
  label: string;
  image: StaticImageData;
  width: number;
  height: number;
}[];

const dataLinks: DataLinks[] = [
  {
    section: 'ARQUIVEI',
    contents: [
      {
        label: 'Funcionalidades',
        url: 'https://arquivei.com.br/#features',
      },
      {
        label: 'Planos',
        url: 'https://arquivei.com.br/planos',
      },
      {
        label: 'Contato',
        url: 'https://arquivei.com.br/contato',
      },
      {
        label: 'Vagas',
        url: 'https://arquivei.com.br/vagas',
      },
      {
        label: 'Termos de uso',
        url: 'https://app.arquivei.com.br/termos',
      },
      {
        label: 'Política de Cookies',
        url: 'https://app.arquivei.com.br/cookies',
      },
    ],
  },
  {
    section: 'CONTEÚDOS',
    contents: [
      {
        label: 'Documentações',
        url: 'https://developers.arquivei.com.br/docs',
      },
      {
        label: 'Blog Engenharia',
        url: 'https://medium.com/engenharia-arquivei',
      },
      {
        label: 'Central de ajuda',
        url: 'https://ajuda.arquivei.com.br/pt-BR',
      },
    ],
  },
  {
    section: 'SOLUÇÕES',
    contents: [
      {
        label: 'Para micro e pequenas empresas',
        url: 'https://arquivei.com.br/#features',
      },
      {
        label: 'Para médias e grandes empresas',
        url: 'https://arquivei.com.br/enterprise',
      },
      {
        label: 'Para escritórios contábeis',
        url: 'https://arquivei.com.br/escritorios-contabeis',
      },
      {
        label: 'Para parceiros',
        url: 'https://arquivei.com.br/parcerias',
      },
      {
        label: 'Para desenvolvedores',
        url: 'https://arquivei.com.br/developers',
      },
    ],
  },
  {
    section: 'REDES SOCIAIS',
    contents: [
      {
        label: 'Logo do Facebook',
        url: 'https://www.facebook.com/arquivei',
        image: fbLogo,
      },
      {
        label: 'Logo do Youtube',
        url: 'https://www.youtube.com/channel/UC6mrvVc7b5mA2SyYPEVg8cw',
        image: youtubeLogo,
      },
      {
        label: 'Logo do Twitter',
        url: 'https://twitter.com/ArquiveiOficial',
        image: twitterLogo,
      },
      {
        label: 'Logo do LinkedIn',
        url: 'https://www.linkedin.com/company/arquivei',
        image: linkedinLogo,
      },
      {
        label: 'Logo do Instagram',
        url: 'https://www.instagram.com/arquiveioficial',
        image: instagramLogo,
      },
    ],
  },
];

const certificates: Certificates = [
  {
    label: 'Logo da GoogleCloud',
    image: googleCloudLogo,
    width: 122,
    height: 20,
  },
  {
    label: 'Logo da Endeavor',
    image: endeavorLogo,
    width: 87,
    height: 18,
  },
  {
    label: 'Logo da CertiSign',
    image: certisignLogo,
    width: 53,
    height: 26,
  },
  {
    label: 'Logo da Norton',
    image: nortonLogo,
    width: 67,
    height: 26,
  },
];

export default function Footer(): React.ReactElement {
  return (
    <Box data-testid="footer-container" sx={{ backgroundColor: 'grey.50' }}>
      <Container maxWidth="xl" sx={{ pb: 2, pt: 8 }}>
        <Grid
          container
          spacing={8}
          sx={{
            textAlign: {
              xs: 'center',
              md: 'left',
            },
          }}
        >
          <>
            {dataLinks.map((item) => {
              return (
                <Grid key={item.section} xs={12} md={3}>
                  <Stack spacing={3}>
                    <Typography level="h4">{item.section}</Typography>
                    {item.section !== 'REDES SOCIAIS' ? (
                      <Stack spacing={1.5}>
                        {item.contents.map((content) => {
                          return (
                            <Link
                              level="h5"
                              key={content.label}
                              underline="none"
                              color="neutral"
                              href={content.url}
                              sx={{
                                '&:hover': {
                                  color: 'neutral.900',
                                },
                              }}
                            >
                              {content.label}
                            </Link>
                          );
                        })}
                      </Stack>
                    ) : (
                      <Stack
                        direction="row"
                        sx={{
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        {item.contents.map((content) => {
                          return (
                            <Link
                              color="neutral"
                              href={content.url}
                              key={`${content.url}+${content.label}`}
                            >
                              {content.image ? (
                                <Image
                                  alt={content.label}
                                  src={content.image}
                                  height={27}
                                  width={27}
                                />
                              ) : null}
                            </Link>
                          );
                        })}
                      </Stack>
                    )}
                  </Stack>
                </Grid>
              );
            })}
          </>
        </Grid>
        <Grid container pt={15} pb={7} spacing={2} alignItems="center">
          <Grid xs={12} md={6} lg={8}>
            <Typography
              level="h5"
              sx={{
                textAlign: {
                  xs: 'center',
                  md: 'left',
                },
              }}
            >
              <strong>{`© ${new Date().getFullYear()}`} ARQUIVEI.</strong>{' '}
              CONSTRUÍDO COM 💙 EM SÃO CARLOS, A CAPITAL DA TECNOLOGIA.
            </Typography>
          </Grid>
          <Grid xs={12} md={6} lg={4}>
            <Stack
              alignItems="center"
              direction="row"
              spacing={8}
              sx={{ justifyContent: 'space-between' }}
            >
              {certificates.map((certificate) => {
                return (
                  <Image
                    alt={certificate.label}
                    src={certificate.image}
                    key={certificate.label}
                    height={certificate.height}
                    width={certificate.width}
                  />
                );
              })}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
