import { Button, Grid, Typography, Stack } from '@arquivei/atenas';
import Image from 'next/image';

import apiArquiveiImg from '../../assets/api-arquivei.png';
import { DOCS_URL } from '../../constants';

const ApiDocumentationSection = () => {
  return (
    <Stack
      direction="row"
      component="section"
      data-testid="documentation-section"
      minHeight="70vh"
    >
      <Stack position="relative" justifyContent="end">
        <Grid container>
          <Grid md={6} xs={12}>
            <Typography level="display1" textColor="common.white" gutterBottom>
              Documentação da API
            </Typography>
            <Typography textColor="common.white">
              Confira nossa documentação com tudo que precisa para começar a
              desenvolver sua aplicação usando nossa API de maneira simples
            </Typography>
            <Button
              component="a"
              href={DOCS_URL}
              color="success"
              variant="solid"
              size="lg"
              sx={{ mt: 8 }}
            >
              Ver documentação
            </Button>
          </Grid>
          <Grid
            md={6}
            xs={12}
            display="flex"
            justifyContent="end"
            sx={{ mb: 6, paddingTop: { xs: 12, md: 0 } }}
          >
            <Image
              src={apiArquiveiImg}
              alt="imagem abstrata de uma tela com a logo da Arquivei conectando-se a um texto escrito API"
              objectPosition="center"
              data-testid="api-arquivei"
              quality={100}
              width="429"
              height="342"
              loading="eager"
            />
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};

export default ApiDocumentationSection;
