import { Box, Button, Grid, Typography, Stack } from '@arquivei/atenas';

import { HELP_URL } from '../../constants';

const NeedHelp = () => {
  return (
    <Stack direction="row" width="100%" minHeight="70vh">
      <Stack zIndex={1} justifyContent="center">
        <Grid container>
          <Grid md={6} xs={12}>
            <Typography level="display1" textColor="common.white" gutterBottom>
              Precisa de ajuda?
            </Typography>
            <Typography textColor="common.white" mt={4}>
              Conheça nossos canais de ajuda e suporte e não fique com dúvida,
              fale com um atendente especializado com agilidade, confira as
              formas para contato.
            </Typography>
          </Grid>
        </Grid>
        <Box>
          <Button
            component="a"
            href={HELP_URL}
            color="success"
            variant="solid"
            size="lg"
            sx={{ mt: 8 }}
          >
            Fale conosco
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

export default NeedHelp;
